import React from 'react';


import InfoSection from './InfoSection';
import {homeObjOne } from './Data'


const AboutSection = () => {
   


    return (

        <>
        
       
        <InfoSection {...homeObjOne} />
         
        </>

     );

    };




export default AboutSection;