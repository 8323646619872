import React from 'react';


import InfoSection from './InfoSection';
import {homeObjTwo } from './Data'


const CertificationSection = () => {
   


    return (

        <>
        
       
        <InfoSection {...homeObjTwo} />
        
         
        </>

     );

    };




export default CertificationSection;