import React from 'react';


import InfoSection from './InfoSection';
import {homeObjThree } from './Data'


const ServicesSection = () => {
   


    return (

        <>
        
       
      
        <InfoSection {...homeObjThree} />
         
        </>

     );

    };




export default ServicesSection;