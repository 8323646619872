

export const homeObjThree = {

id: 'services',
lightBg: false,
lightText: true,
lightTextDesc: true,
headline: 'Our Services',
description: ' PAKT  Consulting Limited network security services include a wide range of solutions to help protect your organizations networks and infrastructure from cyber threats.',
buttonLabel: 'Read More',
imgStart: false,
img: require('../../images/partners-index.png'),
alt: 'Our Services',
dark: false,
primary: true,
darkText: false,
};