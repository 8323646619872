import React from 'react';

import Herosection from '../components/Herosection';
import AboutSection from '../components/AboutSection';
import CertificationSection from '../components/CertificationSection';
import ServicesSection from '../components/ServicesSection';




const Home = () => {
   


    return (

        <>
        
       
        <Herosection />
        <AboutSection />
        <CertificationSection />
        <ServicesSection />
         
        </>

     );

    };




export default Home;